import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators';
import '@iconify/iconify';
import {DateTime} from 'luxon';

@customElement('generation-info')
export class GenerationInfoElement extends LitElement {
  render() {
    return html`<div class="text-center">
      <span>${process.env.COMMIT_REF?.substring(0, 7)}</span>
      |
      <span>${DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss')}</span>
    </div>`;
  }

  createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'generation-info': GenerationInfoElement;
  }
}
